import Image1 from "../../Assets/services/radiator2.jpg"
import Image2 from "../../Assets/services/image_05.jpg"
import Image3 from "../../Assets/services/axle3.jpg"
import Image4 from "../../Assets/services/axle3.jpg"
import Image5 from "../../Assets/services/engine2.jpg"
import Image6 from "../../Assets/services/engine2.jpg"

import Image7 from "../../Assets/services/alternator2.jpg"
import Image8 from "../../Assets/services/alternator2.jpg"
import Image9 from "../../Assets/services/batter2.jpg"
import Image10 from "../../Assets/services/image_05.jpg"
import Image11 from "../../Assets/services/clutch2.jpg"
import Image12 from "../../Assets/services/image_05.jpg"


export const FeaturedData = [
    {
        id: 1,
        img: Image1,
        img2: Image2,
        title: "Radiator",
        description: '3 Row Aluminum Radiator For 1987-2006 Jeep Wrangler YJ TJ 2.4L 2.5L 4.0L L4 L6',
        isNew: false,
        oldPrice: 20,
        price: 114,
        remain: 14,
    },
    {
        id: 2,
        img: Image3,
        img2: Image4,
        title: "Axle",
        description: 'CV Axle Shaft Front Outer Passenger Side Right for Altima Sentra Rogue FWD',
        isNew: true,
        oldPrice: 19,
        price: 82,
        remain: 20,
    },
    {
        id: 3,
        img: Image5,
        img2: Image6,
        title: "Engine",
        description: "Chevy LS Engine Card Holder, LS3 Engine, LS Intake Manifold, LS Valve Covers",
        isNew: true,
        oldPrice: 38,
        price: 30,
        remain: 30,
    },
    {
        id: 4,
        img: Image7,
        img2: Image8,
        title: "Alternator",
        description: "New Alternator For 4.6 V8 Crown Vic Town Car Grand Marquis 1999-2002",
        isNew: false,
        oldPrice: 12,
        price: 99,
        remain: 12,
    },
    {
        id: 5,
        img: Image9,
        img2: Image10,
        title: "Battery",
        description: "Energie PR1000 1000 Watt 12V Power Cell Car Battery",
        isNew: true,
        oldPrice: 10,
        price: 59,
        remain: 10,
    },
    {
        id: 6,
        img: Image11,
        img2: Image12,
        title: "Clutch",
        description: "For Club Car DS 84-96 / Precedent 1997-Up Golf Car Gas Primary Driven Clutch",
        isNew: true,
        oldPrice: 9,
        price: 56,
        remain: 18,
    },
];