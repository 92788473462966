import React, {useEffect, useState} from 'react'
import './About.css'
import AboutImg2 from '../../Assets/about/The art of Salvage-min.jpg'
import AboutImg3 from '../../Assets/Slides/old-rusted-car-in-junk-yard-hoping-to-salvage-some-Car-min.jpg'
import AboutImg4 from '../../Assets/Slides/Vehicle Salvaged-min.jpg'
import { useNavigate } from 'react-router-dom';

import AboutFirst from '../../Assets/services/clients.png'
import AboutSecond from '../../Assets/services/innovation.png'
import AboutThird from '../../Assets/services/team.png'
import AboutFourth from '../../Assets/services/success.png'
import AboutFifth from '../../Assets/services/transparency.png'


import AreDoing from '../../AreDoing/AreDoing'

function About() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [numCardsToShow, setNumCardsToShow] = useState(3);
    const [touchStartX, setTouchStartX] = useState(0);


  const carouselContent = [
    {
      description: "Transparency: We believe in operating with honesty and integrity, and always providing our customers with clear and transparent information about our services and pricing.",
      image: AboutFifth
    },
    {
      description: "Customer Satisfaction: We strive to provide the highest level of customer service and support, ensuring that our clients have a positive and seamless experience with us.",
      image: AboutFirst,
    },
    {
      description: "Expertise: Our team of salvage experts are highly skilled and knowledgeable, with years of experience in the industry. We take pride in offering top-tier advice and guidance to our customers.",
      image: AboutThird,
    },
    {
      description: "Innovation: We are dedicated to staying at the forefront of the salvage industry by constantly exploring new and innovative solutions to improve our services and processes.",
      image: AboutSecond,
    },
    {
      description: "Sustainability: We are committed to minimising our environmental impact by utilising the latest eco-friendly technologies and best practices.",
      image: AboutFourth
    },
  ];



  const updateCardsToShow = () => {
    if (window.innerWidth >= 1024) {
      setNumCardsToShow(3);
    } else if (window.innerWidth >= 768) {
      setNumCardsToShow(2);
    } else {
      setNumCardsToShow(1);
    }
  };

  useEffect(() => {
    updateCardsToShow();
    window.addEventListener('resize', updateCardsToShow);

    return () => {
      window.removeEventListener('resize', updateCardsToShow);
    };
  }, []);

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    const touchEndX = event.changedTouches[0].clientX;
    const touchDistance = touchEndX - touchStartX;

    if (touchDistance > 50) {
      // Swipe right
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      }
    } else if (touchDistance < -50) {
      // Swipe left
      if (currentSlide < Math.ceil(carouselContent.length / numCardsToShow) - 1) {
        setCurrentSlide(currentSlide + 1);
      }
    }
  };
    //Start of sliding arrows

    const Navigate = useNavigate()



    //end of sliding arrows

    //Start of sliding arrows



  //end of sliding arrows

    //Scroll to the top on load-
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    },[]);
    //End of Scroll to the top on load
  return (
    <div className='AboutPum w-full flex flex-col'>
        <div className='w-full h-[25vh] lg:h-[20vh] md:h-[30vh] xl:h-[40vh] pb-8 flex flex-col justify-end bg-gray-100'>
            <div className='flex justify-between'>
                <h3 className='colBlue ml-[10px] md:ml-[100px] my-auto'>ABOUT US</h3>

                <h5 className='mr-[10px] md:mr-[100px] hidden md:flex align-middle text-xs cursor-wait' onClick={()=>{
                    Navigate('/')
                }}>
                    <span className='mr-2 text-gray-600'>For better, for cars</span>
                </h5>
            </div>
        </div>

        <div className='w-full flex flex-col md:flex-row bg-white px-[10px] md:px-[100px] pt-[100px] pb-[10px] gap-[25px]'>
            <div className='flex flex-1 flex-col'>

                <div className='w-full flex flex-col md:flex-row bg-white gap-[25px]'>
                    <div className='flex flex-1 flex-col'>
                        <h3 className=''>Fast. Simple. Hassle-Free</h3>
                        <p className=' text-gray-700 mt-[20px]'>At 360 Salvage we're more than just a salvage company, we're a leading provider of   high-quality and affordable salvage and used auto-parts solutions to vehicle owners across South Africa. With multiple locations throughout the country, we offer comprehensive services that are fast, simple, and hassle-free, that cater to a wide range of needs.</p>
                        <p className='mt-[20px]'>We understand that time is of the essence, which is why our team of highly experienced experts offer fast and efficient services that prioritise our customers, guiding them through the process and answering any questions they may have, helping them find the right solution for their specific needs.</p>
                        <p className='mt-[20px]'>Salvaging a vehicle can be a challenging experience, and we’ve made the process as smooth and stress-free as possible, taking care of the entire process by providing a simplified and seamless customer experience across our end-to-end salvaging solutions, at very competitive pricing. If you’re searching for the perfect spare part, our wide catalogue of quality used parts ensures that you will find what you need. We pride ourselves on our commitment to quality assurance, ensuring that every part is thoroughly inspected and cleaned before being made available to our customers.</p>
                        <p className='mt-[20px]'>Ours is to go above and beyond the expectations of our customers by continuously improving our services to provide the best possible experience, at every touch point.</p>
                    </div>

                    <div className='flex flex-1 flex-col'>
                        <div className='w-full h-[50vh] hidden xl:flex'>
                            <img src={AboutImg2} className='w-full h-full object-cover' alt='' />
                        </div>

                        <div className='flex mt-[25px] gap-[25px]'>
                            <div className='flex flex-1 h-[20vh]'>
                                <img src={AboutImg3} className='w-full h-full object-cover' alt='' />
                            </div>
                            <div className='flex flex-1 h-[20vh]'>
                                <img src={AboutImg4} className='w-full h-full object-cover' alt='' />
                            </div>
                        </div>
                    </div>
                </div>

                <h3 className='mt-[20px]'>Our Mission & Vision</h3>

                <div className='w-full flex flex-col md:flex-row bg-white gap-[25px] my-8'>
                    <div className='flex flex-1 flex-col bg-white shadow-md p-4'>
                        <h3 className='mt-[20px]'>Vision Statement:</h3>
                        <p className='mt-[10px]'>To be the leading provider of fast, efficient, and hassle-free salvage and used auto-parts solutions that exceed customer expectations throughout South Africa.</p>
                    </div>

                    <div className='flex flex-1 flex-col bg-white shadow-md p-4'>
                        <h3 className='mt-[20px]'>Mission Statement:</h3>
                        <p className='mt-[10px]'>At 360 Salvage, our mission is to simplify the salvaging process and provide high-quality and affordable solutions, while prioritising our customers' needs through a comprehensive and seamless customer experience, backed by a commitment to quality assurance and continuous improvement.</p>
                    </div>
                </div>
                
                <h3 className='mt-[20px]'>Our Values</h3>
                <p className='mt-[20px]'>At 360 Salvage, we're guided by a set of core values that define who we are and how we 
                do business. We're committed to providing clear and concise information to our customers, using plain language and visuals to help them understand the process. 
                </p>
            </div>
        </div>

        <div className='w-full flex flex-col md:flex-row bg-white px-[10px] md:px-[100px] pt-[20px] pb-[20px] gap-[25px]'>
            <div className='flex flex-1 flex-col'>

                <div className='mx-auto px-4 py-16 flex flex-col md:flex-row md:flex-wrap w-full gap-x-[5%] xl:gap-x-[5%] gap-y-[30px] mb-8 md:mb-0' onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}>
                    {carouselContent.slice(currentSlide * numCardsToShow, currentSlide * numCardsToShow + numCardsToShow).map((service, index) => (
                        <div key={index} className={`carousel-card p-10 relative w-[98%] md:w-[45%] xl:w-[30%] bg-white shadow-md flex flex-col`}>
                            <div className='span-spany-core mx-auto p-2 rounded-full w-[80px] h-[80px] overflow-hidden'>
                                <img src={service.image} className='w-full h-full object-cover' alt='' />
                            </div>
                            <h4 className='mt-[20px]'>{service.description}</h4>
                        </div>
                    ))}
                </div>

                <div className='mx-auto px-4 py-2 flex md:flex-row md:flex-wrap justify-center align-middle w-full gap-x-[5px] xl:gap-x-[5px] gap-y-[5px] mb-8 md:mb-0'>
                {Array.from({ length: Math.ceil(carouselContent.length / numCardsToShow) }).map((_, index) => (
                    <span
                        key={index}
                        className={`dotts ${currentSlide === index ? 'active' : 'text-gray-700 bg-slate-700'}`}
                        onClick={() => setCurrentSlide(index)}
                    ></span>
                    ))}
                </div>
            </div>
        </div>

        <div className='w-full flex flex-col md:flex-row bg-white px-[10px] md:px-[40px] pt-[20px] pb-[50px] gap-[25px]'>
          <AreDoing />
        </div>

    </div>
  )
}

export default About