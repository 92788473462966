import React, {useEffect} from 'react'
import './Clients.css'
import First from "../Assets/CAR BRANDS/HONDA.png"
import Second from "../Assets/CAR BRANDS/Toyota-logo.jpg"
import Third from "../Assets/CAR BRANDS/audi.jpg"
import Fourth from "../Assets/CAR BRANDS/ford.jpg"
import Fifth from "../Assets/CAR BRANDS/mazda.jpg"
import Sixth from "../Assets/CAR BRANDS/mercedes.png"
import Seventh from "../Assets/CAR BRANDS/mitsubishi.png"
import Eight from "../Assets/CAR BRANDS/tata.png"
function Clients() {
  useEffect(()=>{
    var copy = document.querySelector(".logs").cloneNode(true);
    document.querySelector(".logos-slide").appendChild(copy);

    var copy1 = document.querySelector(".logs1").cloneNode(true);
    document.querySelector(".logos-slide").appendChild(copy1);

    var copy2 = document.querySelector(".logs2").cloneNode(true);
    document.querySelector(".logos-slide").appendChild(copy2);

    var copy3 = document.querySelector(".logs3").cloneNode(true);
    document.querySelector(".logos-slide").appendChild(copy3);

    var copy4 = document.querySelector(".logs4").cloneNode(true);
    document.querySelector(".logos-slide").appendChild(copy4);

    var copy5 = document.querySelector(".logs5").cloneNode(true);
    document.querySelector(".logos-slide").appendChild(copy5);

    var copy6 = document.querySelector(".logs6").cloneNode(true);
    document.querySelector(".logos-slide").appendChild(copy6);

    var copy7 = document.querySelector(".logs7").cloneNode(true);
    document.querySelector(".logos-slide").appendChild(copy7);
  },[])
  return (
    <div className='w-full flex flex-col'>
        <div className='flex align-middle min-h-[30vh]'> 
            <h3 className='AboutH3 my-auto ml-[100px]'>TOP BRANDS</h3>
        </div>

        <div className='redty min-h-[30vh] flex px-[10px] xl:px-[100px] relative overflow-hidden'>
          <div className="logos">
            <div className="logos-slide">
              <img src={First} className="logs" alt="" />
              <img src={Second} className="logs1" alt="" />
              <img src={Third} className="logs2" alt="" />
              <img src={Fourth} className="logs3" alt="" />
              <img src={Fifth} className="logs4" alt="" />
              <img src={Sixth} className="logs5" alt="" />
              <img src={Seventh} className="logs6" alt="" />
              <img src={Eight} className="logs7" alt="" />
            </div>
          </div>
        </div>
    </div>
  )
}

export default Clients