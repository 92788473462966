import React, { useState } from 'react';
import axios from 'axios';
import './Footer.css';
import { Facebook, Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const Navigate = useNavigate();

  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    serviceOption: '',
    inquiry: ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://360.amosbilly.co.ke/send-email', formData);

      if (response.status === 200) {
        Swal.fire({
          title: 'Hello!',
          text: 'Thank you, we will get back to you as soon as possible',
          icon: 'success',
        });

        // Reset the form fields to their initial state
        setFormData(initialFormData);
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className='relative w-full overflow-hidden'>
     
      <div className='footer-container w-full h-full relative top-0 left-0 flex flex-col py-[30px] px-[10px] md:px-[50px]'>
      <div className='flex md:flex xl:flex-row flex-col-reverse w-full px-[10px] md:px-[50px]'>
        <div className='flex flex-col md:flex1 text-white'>
        <div className='flex flex-col md:flex md:flex-row justify-between text-white'>
            <div className='flex flex-col'>
              <h4 className='colGre' onClick={()=>{
                Navigate('/')
              }}>HOME</h4>
              <p className='transs mt-[20px] cursor-pointer hover:text-red-400 text-sm' onClick={()=>{
                Navigate('/about')
              }}>About us</p>
              <p className='transs cursor-pointer hover:text-red-400 text-sm' onClick={()=>{
                Navigate('/services')
              }}>Services</p>
              <p className='transs cursor-pointer hover:text-red-400 text-sm'>News & Blog</p>
              <p className='transs cursor-pointer hover:text-red-400 ease-in-out text-sm' onClick={()=>{
                Navigate('/360shop')
              }}>360 Salvage Shop®</p>
            </div>
            <div className='flex flex-col'>
              <h4 className='colGre'>PRODUCT & SERVICES</h4>
              <p className='transs mt-[20px] cursor-pointer hover:text-red-400 text-sm'  onClick={()=>{
                Navigate(`/service?name=JUNK YOUR CAR`)
              }}>Junk your Car</p>
              <p className='transs cursor-pointer hover:text-red-400 text-sm' onClick={()=>{
                Navigate(`/service?name=GARAGE`)
              }}>Vehicle Parts</p>
              <p className='transs cursor-pointer hover:text-red-400 text-sm' onClick={()=>{
                Navigate(`/service?name=WHEELS AND TIRES`)
              }}>Wheels & Tyres</p>
              <p className='transs cursor-pointer hover:text-red-400 text-sm' onClick={()=>{
                Navigate(`/service?name=VEHICLE PARTS`)
              }}>Mechanical Services</p>
            </div>
          </div>

          <h4>360 -Salvage Plc</h4>

          <p className='mt-[20px]'>We offer an easy way for car owners to get rid of 
          their old vehicles at the best price and consequently help keep the environment clean at the 
          same time. Leave an enquiry and we’ll get in touch 
          Asap!</p>

        </div>
        <div className='flex w-full md:flex2 flex-col px-[5px] md:px-[30px] mt-4 xl:mt-0'>
          <div className='md:flex w-full justify-between flex-col mt-4'>
            <div className='w-[full] flex justify-between mt-2'>
              <input type='text' placeholder='Your name' className='w-[100%] outline-none p-2 border border-white bg-transparent text-gray-200' onChange={handleChange} name="name" />
            </div>
            <div className='w-[full] flex mt-2'>
              <input type='email' placeholder='Your email' className='w-[100%] outline-none p-2 border border-white bg-transparent text-gray-200' onChange={handleChange} name="email" />
            </div>
            <div className='w-[full] flex justify-between mt-2'>
              <input type='tel' placeholder='Your mobile no' className='w-[100%] outline-none p-2 border border-white bg-transparent text-gray-200' onChange={handleChange} name="phone" />
            </div>
            <div className='w-[full] flex mt-2'>
              <select className='border p-2 flex-1 w-[100%]' onChange={handleChange} name="serviceOPtion">
                  <option>--Select service option--</option>
                  <option>Repairs</option>
                  <option>Towing service</option>
                  <option>Spare part</option>
                  <option>Junking</option>
              </select>
            </div>

            <textarea className='w-full min-h-[20vh] bg-white my-[20px] p-[10px] outline-none border hover:bg-transparent hover:text-white' placeholder='Your inquiry' name="inquiry" onChange={handleChange}></textarea>
            
            <button className='ml-auto bg-white p-[10px] mb-[20px] border hover:bg-transparent hover:text-white' type='submit'
              onClick={handleFormSubmit}>SEND MESSAGE</button>
            
          </div>
        </div>
        <div className='flex md:flex3 flex-col'>
          <h4 className='colGre'>FOLLOW US</h4>
          <div className='flex text-white gap-[30px] mt-[20px]'>
            <span className='transs cursor-pointer hover:text-red-400'>
              <Facebook />
            </span>
            <span className='transs cursor-pointer hover:text-red-400'>
              <Instagram />
            </span>
            <span className='transs cursor-pointer hover:text-red-400'>
              <Twitter />
            </span>
            <span className='transs cursor-pointer hover:text-red-400'>
              <YouTube />
            </span>
            <span className='transs cursor-pointer hover:text-red-400'>
              <LinkedIn />
            </span>
          </div>

          <p className='text-white mt-[20px]'>@360salvage_za</p>

          <h4 className='colGre mt-[40px]'>STAY IN TOUCH</h4>
          <p className='text-white mt-[10px] text-sm'>Sign up and get latest news</p>
          <p className='text-white mt-[5px] text-sm'>Environment and Sustainability</p>

          <p className='text-white mt-[40px] text-sm'>+260 - 972 293 076</p>
          <p className='text-white mt-[5px] text-sm'>info@360salvage.co.za</p>
          <p className='text-white mt-[5px] text-sm'>28 John St Selby South</p>
          <p className='text-white mt-[5px] text-sm'>Jo’burg, South Africa</p>
        </div>
      </div>

      <hr className='mt-[20px]' />

      <div className='flex flex-wrap md:flex flex-row text-white justify-around mt-[20px]'>
        <p className='transs cursor-pointer hover:text-red-400 text-xs' onClick={()=>{
          Navigate(`/site-map`)
        }}>Site map</p>
        <p className='transs cursor-pointer hover:text-red-400 text-xs' onClick={()=>{
          Navigate(`/use-of-cookies`)
        }}>Use of cookies</p>
        <p className='transs cursor-pointer hover:text-red-400 text-xs' onClick={()=>{
          Navigate(`/cookie-settings`)
        }}>Cookie Settings</p>
        <p className='transs cursor-pointer hover:text-red-400 text-xs' onClick={()=>{
          Navigate(`/legal-information`)
        }}>Legal Information</p>
        <p className='transs cursor-pointer hover:text-red-400 text-xs' onClick={()=>{
          Navigate(`/privacy-policy`)
        }}>Data Privacy</p>
        <p className='transs cursor-pointer hover:text-red-400 text-xs' onClick={()=>{
          Navigate(`/environment-and-sustainability`)
        }}>Environment & Sustainability</p>
        <p className='transs cursor-pointer hover:text-red-400 text-xs' onClick={()=>{
          Navigate(`/anti-slavery-statement`)
        }}>Anti- slavery Statement`</p>
      </div>

      <div className='flex flex-wrap md:flex flex-row text-white justify-around mt-[20px]'>
        <p className='mt-[20px] mb-[10px]'>© 360 -Salvage Plc 2023. All rights reserved</p>
      </div>
      </div>
    </div>
  )
}

export default Footer