import React, { useEffect, useState } from 'react';
import './WeDo.css';
import Green from "../Assets/logo/Icon_Green_Leaf-11-removebg-preview.png";
import Greener from "../Assets/about/360 Footer Halftone-07-16.png";
import { useNavigate } from 'react-router-dom';

function WeDo() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [numCardsToShow, setNumCardsToShow] = useState(3);
  const [touchStartX, setTouchStartX] = useState(0);

  const carouselContent = [
    {
      title: "SELL OR JUNK YOUR CAR",
      description: "Say goodbye to your old car effortlessly with 360 Salvage's selling and junking options. Fill out our Vehicle Submission Form to receive an instant offer for your car or truck, and we'll handle all the paperwork and make the process as smooth and hassle-free as possible.",
    },
    {
      title: "MECHANICAL SERVICES",
      description: "Keep your vehicle running smoothly with our expert mechanical services. Our highly qualified mechanics can handle any car problem, from routine maintenance to engine changes, for all types of vehicles.",
    },
    {
      title: "GARAGE SERVICES",
      description: "Comprehensive garage services for all vehicle owners in South Africa. Our highly qualified mechanics can handle any car problem, from routine maintenance to engine changes. Trust us to keep your vehicle performing at its best.",
    },
    {
      title: "VEHICLE AUTO PARTS",
      description: "Revive your vehicle with our affordable and high-quality used auto parts. Our vast database covers all makes and models, and each part undergoes thorough cleaning and inspection to ensure quality assurance.",
    },
    {
      title: "WHEELS & TYRES",
      description: "Upgrade your vehicle's style and performance with our top-quality wheels and tyres. Our extensive selection of wheels is sure to fit most makes and models, offering options to suit your budget and preferences. With our high-quality wheels, your vehicle will not only look fantastic but will also last for the long haul.",
    },
    // ... other content
  ];

  const updateCardsToShow = () => {
    if (window.innerWidth >= 1024) {
      setNumCardsToShow(3);
    } else if (window.innerWidth >= 768) {
      setNumCardsToShow(2);
    } else {
      setNumCardsToShow(1);
    }
  };

  useEffect(() => {
    updateCardsToShow();
    window.addEventListener('resize', updateCardsToShow);

    return () => {
      window.removeEventListener('resize', updateCardsToShow);
    };
  }, []);

  const Navigate = useNavigate();

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    const touchEndX = event.changedTouches[0].clientX;
    const touchDistance = touchEndX - touchStartX;

    if (touchDistance > 50) {
      // Swipe right
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      }
    } else if (touchDistance < -50) {
      // Swipe left
      if (currentSlide < Math.ceil(carouselContent.length / numCardsToShow) - 1) {
        setCurrentSlide(currentSlide + 1);
      }
    }
  };

  return (
    <section className='w-full relative overflow-hidden' id='services'>
      <div
        className='WeDoOverlay px-[10px] md:px-[30px] lg:px-[100px] relative md:relative w-full lg:min-h-[100vh] flex flex-col bg-black'
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <img src={Greener} alt='' className='absolute bottom-0 rotate-180 w-[70%]' />
        <h3 className='ml-8 md:ml-0 text-white'>WHAT WE DO</h3>

        <div className='mx-auto px-4 py-16 flex flex-col md:flex-row md:flex-wrap w-full gap-x-[5%] xl:gap-x-[5%] gap-y-[30px] mb-8 md:mb-0'>
          {carouselContent.slice(currentSlide * numCardsToShow, currentSlide * numCardsToShow + numCardsToShow).map((service, index) => (
            <div key={index} className={`carousel-card text-white p-10 relative w-[98%] md:w-[45%] xl:w-[30%]`}>
              <img src={Green} alt='' className='absolute right-0 top-0 w-[50px]' />
              <h2>{service.title}</h2>
              <h4 className='mt-[20px]'>{service.description}</h4>
            </div>
          ))}
        </div>

        <section className='WeDoChild mt-10 text-white mb-8 reveal2'>
          <div className='dots'>
            {Array.from({ length: Math.ceil(carouselContent.length / numCardsToShow) }).map((_, index) => (
              <span
                key={index}
                className={`dot ${currentSlide === index ? 'active' : ''}`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
        </section>

        <div className='WeDoChild mt-10 text-white mb-8 reveal2'>
          <span className='text-white'></span>
          <span onClick={() => {
            Navigate('/services');
          }}>ALL OUR SERVICES</span>
        </div>
      </div>
    </section>
  );
}

export default WeDo;
